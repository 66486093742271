<template>
	<svg viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Vector">
			<g filter="url(#filter0_i_1075_210)">
				<path
					d="M37 18.9867C37 30.0503 26.7484 36.7675 18.5243 37.4442C17.2923 37.5441 9.06824 38.0891 3.74466 31.8715C-0.328726 27.1118 -0.0605011 21.362 0.0486074 18.9867C0.134985 17.1382 0.507772 8.97221 7.25431 3.95361C7.99989 3.39498 12.4415 0.188533 18.5243 0.524619C26.8711 0.987874 37 8.05024 37 18.9821V18.9867Z"
					fill="white"
				/>
			</g>
			<path
				d="M18.4966 1.02385C22.5512 1.24889 27.0639 3.08225 30.5593 6.20074C34.0478 9.31301 36.5 13.6826 36.5 18.9821V18.9867C36.5 24.3499 34.0192 28.6651 30.5166 31.7345C27.0062 34.8108 22.4884 36.6163 18.4833 36.9459C17.2561 37.0454 9.27259 37.559 4.12446 31.5463C0.190097 26.949 0.438986 21.3847 0.548072 19.0098C0.634494 17.1604 1.00999 9.22179 7.55274 4.35479L7.55412 4.35376C8.27421 3.81422 12.5951 0.697779 18.4966 1.02385ZM18.4966 1.02385L18.5243 0.524619L18.4966 1.02385Z"
				stroke="#CFC6BE"
			/>
		</g>
		<defs>
			<filter
				id="filter0_i_1075_210"
				x="0"
				y="0.5"
				width="37"
				height="37"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="-2" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1075_210" />
			</filter>
		</defs>
	</svg>
</template>
